<template>
  <div class="wrap">
    <Loading v-show="$store.state.isLoading"/>
    <!-- <div v-show="!$store.state.isLoading"> -->
      <div class="topBox">
        <div class="lineOne">
          <div class="oneText1">已还期数</div>
          <div class="oneText2">{{pay_terms}}</div>
        </div>
        <div class="lineOne">
          <div class="oneText1">已还本金总和</div>
          <div class="oneText2">{{real_principle}}</div>
        </div>
        <div class="lineOne">
          <div class="oneText1">已还租息总和</div>
          <div class="oneText2">{{real_interest}}</div>
        </div>
        <div class="lineOne">
          <div class="oneText1">未还期数</div>
          <div class="oneText2">{{remain_terms}}</div>
        </div>
        <div class="lineOne">
          <div class="oneText1">未还本金</div>
          <div class="oneText2">{{remain_principle}}</div>
        </div>
        <div class="lineOne">
          <div class="oneText1">未还租息</div>
          <div class="oneText2">{{remain_interest}}</div>
        </div>
        <div class="lineBottom">
          <div class="scheduleLine">
            <img class="scheduleImg" src='@/assets/img/icon/schedule.png' />
            <div class="scheduleText">电子租金计划表 </div>
          </div>
          <div class="scheduleSee" @click='torentalSchedule'>查看</div>
        </div>
      </div>
      <van-list >
        <van-col span="22" offset="1" v-for="(item,id) in list" :key="id">
        <van-col offset="1" class="wrapCol">{{item.p_year}}</van-col>
          <!-- <van-cell v-for="(item,id) in list" :key="id" :title="item.title" is-link /> -->
          <van-col span="24" v-for="(item,id) in item.data" :key="id" style="border-bottom:1px #eee solid;border-radius:15px;">
            <van-cell :center="true" :to="{ path: '/rentPlanPay', query: { contractNumber: contractNumber,period:item.period,status: item.pay_status}}">
              <template #title>
                <van-col span="12" class="custom-title">{{item.period}}</van-col>
                <van-col span="12" style="text-align:right">
                  <van-tag v-if="item.pay_status=='已还款'" round color="#E2F9ED" text-color="#1F8D22" class="custom-status">已还款</van-tag>
                  <van-tag v-if="item.pay_status=='未还款'" round color="#FBD9DC" text-color="#E61223" class="custom-status">未还款</van-tag>
                  <van-tag v-if="item.pay_status=='部分还款'" round color="#FFEDCE" text-color="#D35921" class="custom-status">部分还款</van-tag>
                </van-col>
                <div>
                  <van-col span="12" class="custom-date">{{item.plan_day}}</van-col>
                  <van-col span="10" class="custom-rent">{{item.amount}}</van-col>
                  <van-col span="2" style="text-align:right;padding-top:2px;"><van-icon name="arrow" /></van-col>
                </div>
                </template>
            </van-cell>
          </van-col>
        </van-col>
      </van-list>
    <!-- </div> -->
  </div>
</template>
<script>
  import {rentPlanList,getOpenId,querytype} from '@/server'
  import { Toast } from 'vant';
  import Loading from "@/components/Loading";
  import {setCookie,getCookie,nfmoney,domainUrl} from '@/lib/utils';
  export default {
    components: {
      Loading
    },
  data() {
    return {
      cardId:this.$store.state.cardId,
      contractNumber: this.$route.query.contractNumber,
      // openId:localStorage.getItem("openId") ||this.$store.state.openId,
      openId:getCookie('openId'),
      code:this.$route.query.code || '',
      list: [],
      pay_terms:'',
      real_principle:'',
      real_interest: '',
      remain_terms: '',
      remain_principle: '',
      remain_interest: ''
    };
  },
  mounted(){
    sessionStorage.setItem('key', 3);
    let that = this;
    window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
      if(sessionStorage.getItem('key') == 3) {
        that.$router.push({ path: '/rentPlanCtr' });
      }
      // window.history.pushState('forward', null, '/rentPlanCtr');
    }, false);
    // alert(sessionStorage.getItem('key'))
    // window.addEventListener("popstate", function(e) {  //回调函数中实现需要的功能
    //   window.history.pushState('forward', null, '/rentPlanCtr');
      
    //   if(sessionStorage.getItem('key') == 2 || sessionStorage.getItem('key') == 3) {
    //     // alert("a")
    //     window.history.pushState('forward', null, '/rentPlanCtr');
    //     // window.history.forward(1);
    //   }  
    // }, false);
  },
  created(){
    //如果有openid
    if(this.openId){
      getOpenId({openid:this.openId}).then(res=>{
        if(res.wx_code=='0'){
          this.$store.commit('bindStatus', res.bind_status);
          localStorage.setItem("bindStatus", res.bind_status);
          localStorage.setItem("accessToken", res.access_token);
          this.$store.commit('accessToken', res.access_token);
          localStorage.setItem("openId", res.openid);
          this.$store.commit('openId', res.openid);
          this.$store.commit('cardId', res.card_id);
          localStorage.setItem("cardId", res.card_id);
          if(res.bind_status !=='1'){
            this.$router.push({ path: '/login?redirect=/rentPlanDetail' });
            alert("000")
          }else{
            this.getDetail();
          }
        }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
      })
    } else{
      if(!this.code) {
        this.$store.commit('bindStatus', '1');
        localStorage.setItem("bindStatus", '1');
        let uiCode = encodeURIComponent(domainUrl+'/rentPlanDetail');
        window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxe40be82e1238dfe7&redirect_uri='+uiCode+'&response_type=code&scope=snsapi_userinfo&state=STATE&connect_redirect=1#wechat_redirect';         
      }else{//获取code
        getOpenId({percode:this.code}).then(res=>{
          if(res.wx_code=='0'){
            this.$store.commit('bindStatus', res.bind_status);
            localStorage.setItem("bindStatus", res.bind_status);
            this.$store.commit('accessToken', res.access_token);
            localStorage.setItem("accessToken", res.access_token);
            this.$store.commit('openId', res.openid);
            localStorage.setItem("openId", res.openid);
            this.$store.commit('cardId', res.card_id);
            localStorage.setItem("cardId", res.card_id);
            if(res.bind_status !=='1'){
              this.$router.push({ path: '/login?redirect=/rentPlanDetail' });
            }else{
              this.getDetail();
            }
          }else{
            Toast.fail({
              message: res.msg,
              duration:'3000'
            });
          }
        });
      }
    }
  },
  methods: {
    getDetail(){
      const params = {
        contract_number:this.contractNumber
      }
      rentPlanList(params).then(res=>{
        if(res.status=='200'){
          this.list = res.data
          this.pay_terms = res.pay_terms
          this.real_principle = nfmoney(res.real_principle*100)
          this.real_interest = nfmoney(res.real_interest*100)
          this.remain_terms = res.remain_terms
          this.remain_principle = nfmoney(res.remain_principle*100)
          this.remain_interest = nfmoney(res.remain_interest*100)
        }else{
          Toast.fail({
            message: res.msg,
            duration:'3000'
          });
        }
      })
    },
    torentalSchedule(){
      const querytypeparams = {
        card_id:this.cardId,
        function_type:'button',
        button_name:'查看电子租金计划表',
        pre_menu:sessionStorage.getItem('menu_name')
      }
      querytype(querytypeparams);
      this.$router.push({ path: '/rentalSchedule' ,query: { contractNumber: this.contractNumber }});
    }
  },
};
</script>
<style scoped>
.wrap{
  background: #F8F8F8;
}
.topBox{
  border-radius: 8px;
  background: #ffffff;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 12px;
}
.lineOne{
  width: 38%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
}
.oneText1{
  margin-bottom: 10px;
  color: #999999;
  font-size: 15px;
}
.oneText2{
  color: #333333;
  font-size: 17px;
}
.lineBottom{
  width: 100%;
  height: 64px;
  border-top: 1px solid #EEEEEE;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.scheduleLine{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
}
.scheduleImg{
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
.scheduleText{
  font-size: 15px;
  color: #333333;
}
.scheduleSee{
  margin-right: 20px;
  width: 58px;
  height: 28px;
  border-radius: 8px;
  background-color: #247FFF;
  text-align: center;
  line-height: 28px;
  color: #ffffff;
  font-size: 14px;
}
.wrap{
    /* padding: 20px 0px;  */
    overflow: hidden;
    min-height: 100vh !important;
    /* background-color: #fff;  */
  }
  .wrapCol{
    color:#9F9F9F;
    font-size:13px;
    margin-bottom:10px;
    margin-top: 20px;
  }
  .custom-date {
    color: #999999;
    font-size: 12px;
    /* margin-top: 15px; */
    /* display:block; */
  }
  .custom-status{
    font-size: 9px;
    padding-right: 6px;
    padding-left: 6px; 
  }
  .custom-rent{
    /* margin-top: 15px; */
    text-align: right;
    color: #333333;
    font-size: 15px;
  }
</style>
<style>
#app{
  background: #F8F8F8 !important;
}
</style>